@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    margin-right: auto;
    margin-left: auto;
    
}

@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");


.hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  .hover-underline-animation::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    bottom: -2vh;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
   
  }
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .imageContainer {
    position: relative;
    text-align: center;
    color: white;
    z-index: -1;
  }
  
  .imageContainerText{
    position: absolute;
    color: white;
    top: 25px;
  }
  
  
  .container {
    width: 100%;
  }
  
  .text {
    position: absolute;
    top: 25px;
    font-family: "san-serif";
    font-size: 4vw;
    color: white;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-left: 50%;
    transform: translateX(-200%);
    opacity: 0;
    animation: slide-in-anim 1.5s ease-out forwards;
  }
  .text2 {
    position: absolute;
    top: 2px;
    font-family: "san-serif";
    font-size: 4vw;
    color: white;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-left: 50%;
    transform: translateX(-200%);
    opacity: 0;
    animation: slide-in-anim 1.5s ease-out forwards;
  }
  .text-h2 {
    font-family: "Lexend Deca", sans-serif;
    font-weight: bolder;
  
  }
  .text-p {
    font-weight: 500;
    font-family: "Noto Sans Syloti Nagri", sans-serif;
    text-align: start;
    margin-left: -30%;
    font-size: 3.5vw;
    text-align: center;
  }
  
  .carousel-wrapper{
    margin-left: 25%;
  }
  .contactSec{
    margin: 0 10vw;
  }
  .option1{
    height: 20%;
  }
  .hamburger:focus{
    outline: none;
    border-color: blue;
  }
  
  @keyframes slide-in-anim {
    20% {
      opacity: 0;
    }
    60% {
      transform: translateX(-45%);
    }
    75% {
      transform: translateX(-48%);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%);
    }
  }

  .welcome-component {
    /* Set the background image to cover the full container and maintain aspect ratio */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* Set the height to 100vh to cover the full screen vertically */
    height: 100vh;
  }
  
  /* If you want the content to be centered both horizontally and vertically */
  .welcome-component .flex {
    /* Center the content horizontally */
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
  }

  @layer components {
    .3d-button {
      background-color: #4a90e2; /* Blue background color */
      color: #ffffff; /* White text color */
      padding: 0.75rem 1.5rem;
      border: 2px solid #4a90e2; /* Match the background color for the border */
      border-radius: 8px;
      box-shadow: 0 6px 0 #2969b1; /* This creates the 3D effect with a darker shadow on the bottom */
      transition: transform 0.2s, box-shadow 0.2s; /* Adding a smooth transition when the button is pressed */
    }
  
    .3d-button:hover {
      transform: translateY(2px); /* Lift the button slightly on hover */
      box-shadow: 0 4px 0 #2969b1; /* Darker shadow on hover to enhance the 3D effect */
    }
  
    .3d-button:focus {
      outline: none; /* Remove default focus outline */
      box-shadow: 0 4px 0 #2969b1; /* Darker shadow when the button is focused */
    }
  
    .3d-button:active {
      transform: translateY(6px); /* Pressing the button pushes it further down */
      box-shadow: none; /* Remove shadow when the button is pressed */
    }
  }